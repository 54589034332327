import React, {useEffect, useRef, useState} from 'react';
import {GoogleMap, MarkerF, PolygonF, useJsApiLoader} from "@react-google-maps/api";
import {env, remoteRoutes} from "../../../data/constants";
import {Skeleton} from "@mui/material";
import toast from "react-hot-toast";
import {overrideToastDefaults} from "../../../data/toastDefaults";
import {get} from "../../../utils/ajax";
import {PlotDetails} from "../../modules/profiling/partials/_plotDetails";

interface IProps {
    width: string
    height: string
    zoom: number
    polygons: Array<Array<{ lat: number, lng: number }>>
}

const libraries: any = ['geometry']

const Map = ({width, height, zoom, polygons}: IProps) => {

    const mapRef = useRef(null);
    const markerRef = useRef(null);

    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: env.googleMapsApiKey || '',
        libraries: libraries
    });

    const [selectedPlot, setSelectedPlot] = useState<any>(null);
    const [polygonAreas, setPolygonAreas] = useState<any>([]);
    const [map, setMap] = useState<any>(null);
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

    // Function to calculate the centroid of a polygon
    const calculateCentroid = (polygon: any) => {
        let totalLat = 0;
        let totalLng = 0;
        polygon.forEach((point: any) => {
            totalLat += point.lat;
            totalLng += point.lng;
        });
        return {
            lat: totalLat / polygon.length,
            lng: totalLng / polygon.length
        };
    };

    // Calculate centroids for markers
    const polygonCentroids = polygons.map(polygon => calculateCentroid(polygon));

    const calculateCenter = (polygons: any) => {
        let totalLat = 0;
        let totalLng = 0;
        let totalPoints = 0;

        if (polygons.length === 0){
            totalLat = 0.3476
            totalLng = 32.5825
            totalPoints = 1
        }

        else {
            polygons.forEach((polygon: any) => {
                polygon.forEach((point: any) => {
                    totalLat += point.lat;
                    totalLng += point.lng;
                    totalPoints++;
                });
            });
        }

        return {
            lat: totalLat / totalPoints,
            lng: totalLng / totalPoints
        };
    };

    const mapCenter = calculateCenter(polygons)

    const MapOptions = {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true,
        scrollwheel: true,
        zoom: zoom || 14,
        mapTypeId: "terrain"
    };

    const handlePlotClick = (plot: any) => {
        setDrawerVisible(!drawerVisible)
        setSelectedPlot(plot)
    };

    useEffect(() => {
        const fetchForestCover = () => {
            try {
                get(`${remoteRoutes.forestCheckerService}/get-forest-cover`,
                    (response) => {
                        const {urlTemplate} = response

                        if (map) {
                            const overlay = new window.google.maps.ImageMapType({
                                getTileUrl: (coord, zoom) =>
                                    urlTemplate.replace('{x}', coord.x).replace('{y}', coord.y).replace('{z}', zoom),
                                tileSize: new window.google.maps.Size(256, 256),
                            });
                            map.overlayMapTypes.push(overlay);
                        }
                    })
            } catch (error) {
                toast.error('Error while fetching forest cover', overrideToastDefaults)
            }
        }

        fetchForestCover()
    }, [map])

    useEffect(() => {
        if (isLoaded) {
            const areas: any = polygons.map(polygon => {
                const path = polygon.map((coordinates: any) => new google.maps.LatLng(coordinates.lat, coordinates.lng));
                return google.maps.geometry.spherical.computeArea(path) / (10000 * 50);
            });
            setPolygonAreas(areas);
        }
    }, [isLoaded]);

    if (!isLoaded) {
        return <Skeleton width={'100%'} height={200} variant={'rectangular'}/>
    }

    return (
        <>
            <GoogleMap
                mapContainerStyle={{width, height, borderRadius: 10}}
                center={mapCenter}
                // mapTypeId={"satellite"}
                options={MapOptions}
                onLoad={(map) => setMap(map)}
                zoom={zoom}
            >
                {polygons.map((path, index) => (
                    <PolygonF
                        onClick={() => setDrawerVisible(true)}
                        key={index}
                        paths={path}
                        options={{
                            fillColor: 'yellow',
                            fillOpacity: 0.8,
                            strokeColor: 'green',
                            strokeOpacity: 1,
                            strokeWeight: 2,
                            geodesic: false,
                            zIndex: 1
                        }}
                    />
                ))}

                {polygonCentroids.map((centroid, index: number) => (
                    <MarkerF
                        onClick={() => handlePlotClick(index)}
                        key={index}
                        position={centroid}/>
                ))}

                {selectedPlot && (
                    <PlotDetails close={() => setDrawerVisible(false)} show={drawerVisible} plot={selectedPlot} />
                )}
            </GoogleMap>
        </>
    );
}

export default Map;