import {IButtonProps, PageHeader} from "../../../components/shared/PageHeader";
import TableWrapper from "../../../components/shared/TableWrapper";
import XTable, {ITableColumn} from "../../../components/shared/XTable";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../auth";
import React, {useEffect, useState} from "react";
import {IApiResponse, IPaginationMetadata} from "../../../interfaces/IApiResponse";
import {IState} from "../../../../data/types";
import {HUBS_CONSTANTS, IHubState} from "../../../../data/redux/settings/hubsReducer";
import {remoteRoutes} from "../../../../data/constants";
import {buildUrl} from "../../../../utils/queryBuilder";
import {get} from "../../../../utils/ajax";
import {toast} from "react-hot-toast";
import {overrideToastDefaults, toastMessages} from "../../../../data/toastDefaults";
import {IHub} from "../../settings/interfaces/IHub";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisVertical, faFilter, faSearch} from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../../components/shared/XPagination";
import CreateHubForm from "./modals/CreateHubForm";
import {authService} from "../../../../data/oidc/AuthService";
import {removeTrailingS} from "../../../hooks/mauNormalizer";
import {TableFilterLoader} from "../../../components/shared/loaders/TableFilterLoader";
import {useQuery} from "../../../hooks/useQuery";
import {IBreadcrumb} from "../../../components/shared/Breadcrumbs";

const columns: ITableColumn[] = [
    {
        label: 'Name',
        id: 'name',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Phone Number',
        id: 'phoneNumber',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'District',
        id: 'district',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Sub-county',
        id: 'subCounty',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Village',
        id: 'village',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    }
]

type Props = {
    hubId?: string
    title?: string
}

const Hubs = ({hubId, title}: Props) => {

    const dispatch = useDispatch();
    const hub: IHub | any = authService.getHub()
    const navigate = useNavigate()

    // const mau: string | undefined = hubDetails.minimumAggregationUnit !== "" ? hubDetails.minimumAggregationUnit : hub.minimumAggregationUnit;

    const {currentUser} = useAuth();
    const [page, setPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const [selected, setSelected] = useState<any[]>([])
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([])
    const [childHubs, setChildHubs] = useState<IHub[]>([])

    const [pagination, setPagination] = useState<IPaginationMetadata>({
        pageSize: 8,
        hasNextPage: false,
        currentPage: 1,
        hasPreviousPage: false,
        totalItems: 0,
        totalPages: 0
    });

    const {data, loading}: IHubState = useSelector((state: IState) => state.hubs);
    const [showActionButtons, setShowActionButtons] = useState<boolean>(true)
    const [actionButtons, setActionButtons] = useState<IButtonProps[]>([])

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined)
        }

        if (event.keyCode === 13) {
            setSearchTerm(event.target.value)
        }
    }

    const setLoading = (state: boolean) => {
        dispatch({
            type: HUBS_CONSTANTS.LOADING_DATA,
            payload: state
        })
    }

    useEffect(() => {
        setLoading(true);
        handleFetchData(page, pagination.pageSize);

        setBreadcrumbs([
            // {label: mau?.toUpperCase() ?? "Hubs", url: undefined},
            // {label: 'Profiles', url: undefined}
        ])

        setActionButtons([
            {
                label: `Add New`,
                cssClass: "btn btn-primary btn-sm",
                dataTarget: "#new-hub",
                dataToggle: "modal"
            }
        ])

    }, [page, searchTerm, hubId]);

    const handleFetchData = (page: number, pageSize: number) => {
        if (remoteRoutes.onboardingService) {
            const parentHubId = hubId ?? currentUser?.hubId;
            let params: any = {parentHubId, page, pageSize};

            if (searchTerm) {
                params.searchTerm = searchTerm;
            }

            const url = buildUrl(remoteRoutes.onboardingService, '/hubs', params);
            get(url,
                (response: IApiResponse) => {
                    const {data, paginationMetadata} = response;
                    setPagination(paginationMetadata);

                    setChildHubs(data)

                    toast.success(toastMessages.default.success, overrideToastDefaults);
                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({type: HUBS_CONSTANTS.STOP_FETCH});
                    setLoading(false);
                });

            // //Fetech MAU
            // const NewId = urlParams.hubId ?? currentUser?.hubId;
            // const hubUrl = buildUrl(remoteRoutes.onboardingService, `/hubs/${NewId}`)
            // get(hubUrl,
            //     (response: IHub) => {
            //         setHubDetails(response)
            //
            //         dispatch({
            //             type: HUBS_CONSTANTS.GET_DETAILS,
            //             payload: response
            //         })
            //     },
            //     () => {
            //     },
            //     () => dispatch({type: HUBS_CONSTANTS.STOP_FETCH}))
        }
    }


    const handleSelectAll = (rows: any[]) => {
        setSelected(prevSelected => {
            if (prevSelected.length === rows.length) {
                return [];
            } else {
                return rows.map(row => row.id);
            }
        })
    };

    const handleCheckRow = (item: IHub) => {

        setSelected(prevSelected => {
            const newSelected = prevSelected ? [...prevSelected] : []

            const index = newSelected.indexOf(item.id);
            if (index > -1) {
                newSelected.splice(index, 1);
            } else {
                newSelected.push(item.id);
            }

            return newSelected
        })
    };

    const handleClickRow = (item: IHub) => {
        navigate(`/hubs/${item.id}`)
    };

    return (
        <>
            {title && <PageHeader breadcrumbs={breadcrumbs}/>}
            <TableWrapper>
                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="input-group w-25">
                        <input type="text" onKeyUp={(event) => handleSearch(event)}
                               className="form-control"
                               placeholder="Type to search..."/>
                        <span className="input-group-text" id="addon-wrapping">
                                <FontAwesomeIcon icon={faSearch}/>
                            </span>
                    </div>

                    <div className="action-buttons">
                        {!loading && showActionButtons &&
                            actionButtons.map((button, index) => {
                                return (<button
                                    data-bs-toggle={button.dataToggle}
                                    data-bs-target={button.dataTarget}
                                    className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                    key={index}
                                    onClick={button.onClick}>
                                    {button.processing ? 'Please wait...' : button.label}
                                </button>)
                            })
                        }

                    </div>
                </div>

                <XTable
                    selected={selected}
                    onSelectAll={(rows) => handleSelectAll(rows)}
                    onRowClick={(row) => handleClickRow(row)}
                    onCheckRow={(row) => handleCheckRow(row)}
                    data={childHubs}
                    columns={columns}
                    loading={loading}/>

                <XPagination
                    dataLength={childHubs.length}
                    pagination={pagination}
                    currentPage={page}
                    setPage={(page) => setPage(page)}/>

            </TableWrapper>

            <CreateHubForm hubId={hubId}/>
        </>
    );
}

export default Hubs;