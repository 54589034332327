import { Field, Formik } from "formik";
import { ModalWrapper } from "../../../../components/shared/ModalWrapper";
import { XTextField } from "../../../../components/shared/forms/XTextField";

const RolesAndPermissionForm = () => {
  const handleSubmit = () => {};
  const initialValues = {
    roleName: "",
    roleDescription: "",
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting, handleSubmit, values }) => {
        return (
          <ModalWrapper
            id={"add-role"}
            title={"Create New Role"}
            submitting={isSubmitting}
            handleSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  label="Role Name"
                  name={"roleName"}
                  values={values.roleName}
                  disabled={isSubmitting}
                  as={XTextField}
                />
              </div>
              <div className="col-lg-6">
                <Field
                  type={"string"}
                  label={"Description"}
                  as={XTextField}
                  name={"description"}
                />
              </div>
              <div>
                <h6>Permissions</h6>
              </div>
            </div>
          </ModalWrapper>
        );
      }}
    </Formik>
  );
};

export default RolesAndPermissionForm;
