import { XSidebarWrapper } from "../../components/shared/XSidebarWrapper";
import { IHeaderButtonProps } from "../../components/shared/SettingsPageHeader";
import DeleteInputStockForm from "./modals/DeleteInputStockForm";
import EditInputStockForm from "./modals/EditInputStockForm";
import { IInputDistribution } from "./interfaces/IInputDistribution";

type Props = {
  close: () => void;
  show: boolean;
  selectedRow: IInputDistribution | null;
};

const InputPurchaseDetails = ({ close, show, selectedRow }: Props) => {
  const actionButtons: IHeaderButtonProps[] = [
    {
      label: "Delete",
      cssClass: "btn btn-outline-secondary",
      dataTarget: "#delete-input-stock",
      dataToggle: "modal",
    },
    {
      label: "Edit",
      cssClass: "btn btn-primary",
      dataTarget: "#edit-input-stock",
      dataToggle: "modal",
    },
  ];

  return (
    <>
      <XSidebarWrapper close={close} show={show} width={35}>
        <div className="d-flex justify-content-start w-100 align-items-center">
          <div className="py-3 px-3 rounded-1 bg-primary">
            <h2>
              {selectedRow?.inputName &&
                selectedRow?.inputName[0].toUpperCase()}
            </h2>
          </div>
          <div className="px-3">
            <h5>{selectedRow?.inputName}</h5>
            <div className="small">
              UGX {Number(selectedRow?.totalCost).toLocaleString()}
            </div>
            <div className="fs-6 small">
              <span className="badge text-bg-info">
                {Number(selectedRow?.quantity).toLocaleString() +
                  " " +
                  selectedRow?.unitName}
              </span>
            </div>
          </div>
        </div>
        <hr />
        <div className="p-3 bg-gray rounded-3 mb-2">
          <div className="row">
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">Stocking Date</strong>
              <br />
              <span>{selectedRow?.date}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">Input Category</strong>
              <br />
              <span>{"-"}</span>
            </div>
            <div className="col-lg-6 col-12">
              <strong className="small fw-semibold">Unit Price</strong>
              <br />
              <span>UGX {Number(selectedRow?.unitCost).toLocaleString()}</span>
            </div>
            <div className="col-lg-6 col-12">
              <strong className="small fw-semibold">Collection Center</strong>
              <br />
              <span>{selectedRow?.collectionCentreName || "-"}</span>
            </div>
          </div>
        </div>
        <div className="p-3 bg-gray rounded-3 mb-2">
          <div className="row">
            <div className="col-lg-6 col-12 mb-4">
              <span className="small fw-semibold">Source</span>
              <br />
              <span>{"-"}</span>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <strong className="small fw-semibold">Source Contact</strong>
              <br />
              <span>{"-"}</span>
            </div>
            <div className="col-lg-6 col-12">
              <strong className="small fw-semibold">Agent</strong>
              <br />
              <span>{"-"}</span>
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="d-flex justify-content-end gap-3 w-50">
            {actionButtons.map((button, index) => {
              return (
                <button
                  key={index}
                  className={button.cssClass}
                  data-bs-toggle={button.dataToggle}
                  data-bs-target={button.dataTarget}
                  onClick={() => button.onClick}
                >
                  {button.label}
                </button>
              );
            })}
          </div>
        </div>
      </XSidebarWrapper>

      <DeleteInputStockForm item={selectedRow} />
      <EditInputStockForm initialValues={selectedRow} />
    </>
  );
};

export default InputPurchaseDetails;
