import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PageHeaderLoader } from "../../components/shared/loaders/PageHeaderLoader";
import { IState } from "../../../data/types";
import { InnerMenu } from "../../components/shared/InnerMenu";
import { Modules } from "../../interfaces/Enums";
import DashboardLoader from "../../components/shared/loaders/DashboardLoader";
import { IPurchaseState } from "../../../data/redux/inventory/purchasesReducer";
import { InventoryVersusSalesGraph } from "./graphs/InventoryVersusSalesGraph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendarDays,
    faFilter,
    faShop,
    faCaretDown,
    faMoneyBill,
    faClipboardCheck,
    faBoxArchive,
    faCaretUp
} from "@fortawesome/free-solid-svg-icons";
import InventoryStockProgress from "./graphs/InventoryStockProgress";
import InventorySalesProgress from "./graphs/InventorySalesProgress";
import { PageHeader } from "../../components/shared/PageHeader";
import XSummaryCard from "../../components/shared/XSummaryCard";
import { IHub, IProduct } from "../settings/interfaces/IHub";
import { authService } from "../../../data/oidc/AuthService";
import { InventoryCostsVersusRevenueGraph } from "./graphs/InventoryCostsVersusRevenueGraph";

export const Dashboard = () => {
    const { loading }: IPurchaseState = useSelector((state: IState) => state.purchases);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const [products, setProducts] = useState<any[] | undefined>(undefined);
    const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
    const [initialProductSet, setInitialProductSet] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const hub: IHub | undefined = authService.getHub();
    useEffect(() => {
        if (hub) {
            const { products } = hub;
            const sortedProducts = products?.map((product: IProduct) => ({
                id: product.id,
                label: product.name,
            })).sort((a, b) => a.label.localeCompare(b.label));

            setProducts(sortedProducts || []);
        }
    }, [hub]);

    useEffect(() => {
        if (products && products.length > 0 && !initialProductSet) {
            setSelectedItem(products[0].label);
            setSelectedProductId(products[0].id);
            setInitialProductSet(true);
        }
    }, [products, initialProductSet]);

    const handleItemClick = (item: any) => {
        setSelectedItem(item.label);
        setSelectedProductId(item.id);
        setIsOpen(false);
    };

    return (
        <>
            {loading && <>
                <PageHeaderLoader />
                <DashboardLoader />
            </>}

            {!loading && <>

                <div className="my-3">
                    <InnerMenu module={Modules.Inventory} />
                </div>

                <div className="row mb-3">
                    <div className="col-12">
                        <div className="card border-0 shadow">
                            <div className="card-body d-flex w-100 justify-content-between">
                                <div className="d-flex justify-content-start align-items-center w-75">
                                    <div className="bg-gray ps-3 pe-3 pt-1 pb-1 rounded-5 me-2">
                                        <FontAwesomeIcon icon={faCalendarDays} size={"sm"} />
                                        <span className={"p-1"}>Last 30 Days</span>
                                    </div>
                                    <div className="bg-gray ps-3 pe-3 pt-1 pb-1 rounded-5 me-2">
                                        <FontAwesomeIcon icon={faShop} />
                                        <span className={"p-1"}>All Collection Centers</span>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end align-items-center w-25">
                                    <button className="btn btn-secondary">
                                        <FontAwesomeIcon icon={faFilter} color={"#1F5A2D"} className="me-2" />
                                        Filters
                                    </button>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <div className="card w-100 border-0 mb-3">
                            <div className="card-body" style={{ minHeight: 200 }}>
                                <h6>Products in stock</h6>
                                <InventoryStockProgress />
                            </div>
                        </div>
                        <div className="card w-100 border-0 mb-3">
                            <div className="card-body">
                                <h6>Product Stock levels at all Collection Centers</h6>
                                <InventorySalesProgress />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="row mb-3">
                            <div className="col-lg-4">
                                <XSummaryCard
                                    bgColor="#1C5838"
                                    textColor="white"
                                    iconBgColor="white"
                                    iconColor="orange"
                                    isLoading={loading}
                                    label={"Amount from Sales"}
                                    value={"13.7M"}
                                    icon={faMoneyBill} />
                            </div>
                            <div className="col-lg-4">
                                <XSummaryCard
                                    bgColor="#1C5838"
                                    textColor="white"
                                    iconBgColor="white"
                                    iconColor="orange"
                                    isLoading={loading}
                                    label={"Stock turnover rate"}
                                    value={"4"}
                                    icon={faClipboardCheck} />
                            </div>
                            <div className="col-lg-4">
                                <XSummaryCard
                                    bgColor="#1C5838"
                                    textColor="white"
                                    iconBgColor="white"
                                    iconColor="orange"
                                    isLoading={loading}
                                    label={"Days of Inventory at hand"}
                                    value={"2"}
                                    icon={faBoxArchive} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 mb-3">
                                <div className="col card border-0" style={{ height: 445, borderRadius: 12 }}>
                                    <h6 className={"p-3"}>Inputs Inventory Vs Input Sales</h6>
                                    <InventoryCostsVersusRevenueGraph />
                                </div>
                            </div>
                            <div className="col-lg-12 mb-3">
                                <div className="col card border-0" style={{ height: 445, borderRadius: 12 }}>
                                    <div className="row justify-content-between mt-3 px-3">
                                        <div className="col-lg"><h6>Purchases Vs Sales</h6></div>
                                        <div className="col-lg-3 align-items-center text-center justify-content-end mx-3" style={{ position: "relative", }}>
                                            <button
                                                onClick={toggleDropdown}
                                                className="btn btn-secondary justify-content-between mx-3 w-100"><span>{selectedItem || "Select a Product"}</span>
                                                <span style={{ marginLeft: "5%" }}>
                                                    <FontAwesomeIcon
                                                        icon={isOpen ? faCaretUp : faCaretDown}
                                                        size="sm"
                                                        color="#414241"
                                                    />
                                                </span>
                                            </button>

                                            {isOpen && (
                                                <ul className="top-10 bg-gray w-100 mt-1 p-0"
                                                    style={{
                                                        position: "absolute",
                                                        left: 0,
                                                        borderRadius: 4,
                                                        listStyle: "none",
                                                        zIndex: 1,
                                                    }}>
                                                    {products && products.length > 0 ? (
                                                        products.map((prod) => (
                                                            <li
                                                                key={prod.id}
                                                                onClick={() => handleItemClick(prod)}
                                                                style={{
                                                                    padding: "8px 12px",
                                                                    cursor: "pointer",
                                                                    borderBottom: "1px solid #EAEAEA",
                                                                    color: selectedItem === prod.label ? "#414241" : "#000",
                                                                }}>
                                                                {prod.label}
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <li>No products available</li>
                                                    )}
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                    <InventoryVersusSalesGraph productId={selectedProductId} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    );
};
