import {
    ICategories,
    ICollectionCentre,
    IContactPerson, ICostCentre,
    IHub,
    IProcess,
    IUnit
} from "../../../app/modules/settings/interfaces/IHub";
import { IInput } from "../../../app/modules/settings/inputs/interfaces/IInput";
import { IUser } from "../../../app/modules/onboarding/userManagement/interfaces/IUser";
import { IPaginationMetadata } from "../../../app/interfaces/IApiResponse";

export const SETTINGS_CONSTANTS = {
    STOP_FETCH: "STOP_FETCH",
    FETCH_DATA: "FETCH_DATA",
    POST_DATA: "POST_DATA",
    POST_INPUTS: "POST_INPUTS",
    POST_PRODUCTS: "POST_PRODUCTS",
    POST_COST_CENTRES: "POST_COST_CENTRES",
    POST_PROCESSES: "POST_PROCESSES",
    POST_UNITS: "POST_UNITS",
    POST_COLLECTION_CENTRES: "POST_COLLECTION_CENTRES",
    POST_CONTACT_PEOPLE: "POST_CONTACT_PEOPLE",
    POST_USERS: "POST_USERS",
    GET_DETAILS: "GET_DETAILS",
    LOADING_DATA: "LOADING_DATA",
    SET_SORT_PARAMS: "SET_SORT_PARAMS",
    FILTER_DATA: "FILTER_DATA",
    RESET_FILTER: "RESET_FILTER",
    PAGINATION: "PAGINATION",
    FETCH_INPUTS: "FETCH_INPUTS",
    FETCH_PRODUCTS: "FETCH_PRODUCTS",
    FETCH_COST_CENTRES: "FETCH_COST_CENTRES",
    FETCH_PROCESSES: "FETCH_PROCESSES",
    FETCH_COLLECTION_CENTRES: "FETCH_COLLECTION_CENTRES",
    FETCH_CONTACT_PEOPLE: "FETCH_CONTACT_PEOPLE",
    FETCH_UNITS: "FETCH_UNITS",
    FETCH_USERS: "FETCH_USERS",
    FETCH_CATEGORIES: "FETCH_CATEGORIES",
};

export interface ISettingsState {
    loading: boolean
    inputs: {
        data: IInput[],
        pagination: IPaginationMetadata
    },
    products: [],
    costCenters: {
        data: ICostCentre[],
        pagination: IPaginationMetadata
    },
    processes: {
        data: IProcess[],
        pagination: IPaginationMetadata
    },
    collectionCentres: {
        data: ICollectionCentre[],
        pagination: IPaginationMetadata
    },
    contactPeople: {
        data: IContactPerson[],
        pagination: IPaginationMetadata
    },
    users: [],
    roles: [],
    units: {
        data: IUnit[],
        pagination: IPaginationMetadata
    },
    categories: ICategories[],

    filter: {
        show: boolean;
        inputs: [];
        products: [];
        costCenters: [];
        processes: [];
        collectionCentres: [];
        contactPeople: [];
        users: [];
        units: [];
        range: {
            startDate: string;
            endDate: string;
        };
        key: string;
        pagination: {
            itemsPerPage: number;
            activePage: number;
            isLastPage: boolean;
        };
    };
    sorting: {
        order: string;
        column: string;
    };
    reset: boolean;
}

const initialState: ISettingsState = {
    loading: false,
    inputs: {
        data: [],
        pagination: {
            currentPage: 1,
            pageSize: 10,
            hasPreviousPage: false,
            hasNextPage: false,
            totalItems: 0,
            totalPages: 0
        }
    },
    products: [],
    costCenters: {
        data: [],
        pagination: {
            currentPage: 1,
            pageSize: 10,
            hasPreviousPage: false,
            hasNextPage: false,
            totalItems: 0,
            totalPages: 0
        }
    },
    processes: {
        data: [],
        pagination: {
            currentPage: 1,
            pageSize: 5,
            hasPreviousPage: false,
            hasNextPage: false,
            totalItems: 0,
            totalPages: 0
        }
    },
    collectionCentres: {
        data: [],
        pagination: {
            currentPage: 1,
            pageSize: 5,
            hasPreviousPage: false,
            hasNextPage: false,
            totalItems: 0,
            totalPages: 0
        }
    },
    contactPeople: {
        data: [],
        pagination: {
            currentPage: 1,
            pageSize: 5,
            hasPreviousPage: false,
            hasNextPage: false,
            totalItems: 0,
            totalPages: 0
        }
    },
    users: [],
    units: {
        data: [],
        pagination: {
            currentPage: 1,
            pageSize: 5,
            hasPreviousPage: false,
            hasNextPage: false,
            totalItems: 0,
            totalPages: 0
        }
    },
    categories: [],
    roles: [],
    filter: {
        key: '',
        show: false,
        inputs: [],
        products: [],
        costCenters: [],
        processes: [],
        collectionCentres: [],
        contactPeople: [],
        users: [],
        units: [],
        range: {
            startDate: '',
            endDate: ''
        },
        pagination: {
            itemsPerPage: 10,
            activePage: 1,
            isLastPage: false
        }
    },
    sorting: {
        order: 'asc',
        column: ''
    },
    reset: false
}


export default function settingsReducer(state = initialState, action: any) {
    switch (action.type) {
        case SETTINGS_CONSTANTS.FILTER_DATA:
            return { ...state, ...action.payload, show: !state.filter.show };

        case SETTINGS_CONSTANTS.FILTER_DATA:
            return { ...state, ...action.payload, show: !state.filter.show }

        case SETTINGS_CONSTANTS.RESET_FILTER:
            initialState.filter.show = true
            return { ...initialState, reset: true }

        case SETTINGS_CONSTANTS.SET_SORT_PARAMS:
            return { ...state, ...action.payload }

        case SETTINGS_CONSTANTS.PAGINATION:
            return { ...state, ...action.payload }

        case SETTINGS_CONSTANTS.FETCH_INPUTS: {
            return { ...state, loading: false, inputs: action.payload, reset: false };
        }

        case SETTINGS_CONSTANTS.FETCH_CATEGORIES: {
            return { ...state, loading: false, categories: action.payload, reset: false };
        }

        case SETTINGS_CONSTANTS.FETCH_USERS: {
            return { ...state, loading: false, users: action.payload, reset: false };
        }

        case SETTINGS_CONSTANTS.FETCH_UNITS: {
            return { ...state, loading: false, units: action.payload, reset: false };
        }

        case SETTINGS_CONSTANTS.FETCH_PRODUCTS: {
            return { ...state, loading: false, products: action.payload, reset: false };
        }

        case SETTINGS_CONSTANTS.FETCH_COST_CENTRES: {
            return { ...state, loading: false, costCenters: action.payload, reset: false };
        }

        case SETTINGS_CONSTANTS.FETCH_PROCESSES: {
            return { ...state, loading: false, processes: action.payload, reset: false };
        }

        case SETTINGS_CONSTANTS.FETCH_COLLECTION_CENTRES: {
            return { ...state, loading: false, collectionCentres: action.payload, reset: false };
        }

        case SETTINGS_CONSTANTS.FETCH_CONTACT_PEOPLE: {
            return { ...state, loading: false, contactPeople: action.payload, reset: false };
        }

        case SETTINGS_CONSTANTS.POST_INPUTS: {
            const input: IInput = action.payload.data
            const pagination: IPaginationMetadata = action.payload.pagination
            return {
                ...state, inputs: {
                    data: [input, ...state.inputs.data],
                    pagination
                }
            }
        }

        case SETTINGS_CONSTANTS.POST_UNITS: {
            const unit: IUnit = action.payload.data
            const pagination: IPaginationMetadata = action.payload.pagination
            return {
                ...state, units: {
                    data: [unit, ...state.units.data],
                    pagination
                }
            }
        }

        case SETTINGS_CONSTANTS.POST_PROCESSES: {
            const processes: IProcess = action.payload.data
            const pagination: IPaginationMetadata = action.payload.pagination
            return {
                ...state, processes: {
                    data: [processes, ...state.processes.data],
                    pagination
                }
            }
        }

        case SETTINGS_CONSTANTS.POST_USERS: {
            const users: IUser = action.payload
            return { ...state, users: [users, ...state.users] }
        }

        case SETTINGS_CONSTANTS.POST_COLLECTION_CENTRES: {
            const collectionCentre: ICollectionCentre = action.payload.data
            const pagination: IPaginationMetadata = action.payload.pagination
            return {
                ...state, collectionCentres: {
                    data: [collectionCentre, ...state.collectionCentres.data],
                    pagination
                }
            }
        }

        case SETTINGS_CONSTANTS.POST_COST_CENTRES: {
            const costCenter: ICostCentre = action.payload.data
            const pagination: IPaginationMetadata = action.payload.pagination
            return {
                ...state, costCenters: {
                    data: [costCenter, ...state.costCenters.data],
                    pagination
                }
            }
        }

        case SETTINGS_CONSTANTS.POST_CONTACT_PEOPLE: {
            const contactPerson: IContactPerson = action.payload.data
            const pagination: IPaginationMetadata = action.payload.pagination
            return {
                ...state,
                contactPeople: {
                    data: [contactPerson, ...state.contactPeople.data],
                    pagination
                }
            }
        }

        case SETTINGS_CONSTANTS.LOADING_DATA: {
            return { ...state, loading: action.payload }
        }

        case SETTINGS_CONSTANTS.GET_DETAILS: {
            return { ...state, requestDetails: action.payload }
        }

        case SETTINGS_CONSTANTS.STOP_FETCH: {
            return { ...state, loading: false }
        }

        default: {
            return state
        }
    }
}