import { useEffect, useState } from "react";
import { ITableColumn, XTable } from "../../../components/shared/XTable";
import { IHeaderButtonProps, SettingsPageHeader } from "../../../components/shared/SettingsPageHeader";
import TableWrapper from "../../../components/shared/TableWrapper";
import { IInput } from "../interfaces/IHub";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../auth";
import { IApiResponse } from "../../../interfaces/IApiResponse";
import { IState } from "../../../../data/types";
import { ISettingsState, SETTINGS_CONSTANTS } from "../../../../data/redux/settings/settingsReducer";
import { remoteRoutes } from "../../../../data/constants";
import { buildUrl } from "../../../../utils/queryBuilder";
import { get } from "../../../../utils/ajax";
import { toast } from "react-hot-toast";
import { overrideToastDefaults, toastMessages } from "../../../../data/toastDefaults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import XPagination from "../../../components/shared/XPagination";
import CreateNewInputForm from "./modals/CreateNewInputForm";
import HubInputDetails from "./HubInputDetails";
import { IHubInput } from "./interfaces/IInput";

const columns: ITableColumn[] = [
    {
        label: 'Input Name',
        id: 'name',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Description',
        id: 'description',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Input Category',
        id: 'categoryName',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
    {
        label: 'Units',
        id: 'unitsString',
        link: undefined,
        isNumberFormat: false,
        isDate: false,
        textAlign: 'text-start'
    },
]

type IProps = {
    title?: string
    hubId?: string
}

const HubInputsPage = ({ title, hubId }: IProps) => {

    const dispatch = useDispatch();

    const initialValues: IHubInput = {
        hubId: '',
        name: '',
        category: '',
        otherInputCategory: '',
        description: '',
        units: []
    }

    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<any[]>([])
    const [filteredInputs, setFilteredInputs] = useState<IInput[]>([]);

    const { inputs, loading }: ISettingsState = useSelector((state: IState) => state.settings);
    const { pagination, data } = inputs
    const [page, setPage] = useState<number>(1);

    const [showActionButtons, setShowActionButtons] = useState<boolean>(true)
    const [selectedInput, setSelectedInput] = useState<IHubInput>(initialValues);

    const handleSearch = (event: any) => {
        if (event.target.value === '') {
            setSearchTerm(undefined)
        }

        if (event.keyCode === 13) {
            setSearchTerm(event.target.value)
        }
    }

    const setLoading = (state: boolean) => {
        dispatch({
            type: SETTINGS_CONSTANTS.LOADING_DATA,
            payload: state
        })
    }

    const auth = useAuth();
    const inputCategories = auth.categories?.inputCategories;

    const filterInputs = () => {
        const data: IInput[] = inputs.data.map((input: IInput) => ({
            ...input,
            categoryName: inputCategories?.find(i => i.number.toString() == input.category)?.name ?? 'cc',
            unitsString: input.units ? input.units.map(u => u.name).join(',') : ''
        }));
        if (searchTerm) {
            setFilteredInputs(data.filter(input =>
                input.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                input.category?.toString().toLowerCase().includes(searchTerm.toLowerCase())
            ));
        } else {
            setFilteredInputs(data);
        }
    }

    useEffect(() => {
        filterInputs()
    }, [inputs, searchTerm]);

    useEffect(() => {
        setLoading(true);
        handleFetchData(page, pagination.pageSize);
    }, [page]);

    const handleFetchData = (page: number, pageSize: number) => {
        if (remoteRoutes.onboardingService) {
            let params: any = { hubId, page, pageSize };

            if (searchTerm) {
                params.searchTerm = searchTerm;
            }

            const url = buildUrl(remoteRoutes.onboardingService, '/inputs', params);
            get(url,
                (response: IApiResponse) => {
                    const { data, paginationMetadata } = response;

                    dispatch({
                        type: SETTINGS_CONSTANTS.FETCH_INPUTS,
                        payload: { data, pagination: paginationMetadata }
                    });

                },
                async (error) => {
                    toast.error(toastMessages.default.fail, overrideToastDefaults);
                },
                () => {
                    dispatch({ type: SETTINGS_CONSTANTS.STOP_FETCH });
                    setLoading(false);
                });
        }
    }

    const handleSelectAll = (rows: any[]) => {
        setSelected(prevSelected => {
            if (prevSelected.length === rows.length) {
                return [];
            } else {
                return rows.map(row => row.id);
            }
        })
    };

    const handleCheckRow = (item: IHubInput) => {

        setSelected(prevSelected => {
            const newSelected = prevSelected ? [...prevSelected] : []

            const index = newSelected.indexOf(item.id);
            if (index > -1) {
                newSelected.splice(index, 1);
            } else {
                newSelected.push(item.id);
            }

            return newSelected
        })
    };

    const handleClickRow = (item: IHubInput) => {
        setDrawerVisible(!drawerVisible);
        setSelectedInput(item)
    };

    const actionButtons: IHeaderButtonProps[] = [{
        label: "Add an Input",
        cssClass: "btn btn-primary btn-sm",
        dataTarget: "#create-new-input",
        dataToggle: "modal"
    }];

    return (
        <>
            {title && <SettingsPageHeader title={title} />}
            <TableWrapper>
                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div className="input-group w-auto">
                        <input type="text" onKeyUp={(event) => handleSearch(event)}
                            className="form-control"
                            placeholder="Type to search..." />
                        <span className="input-group-text" id="addon-wrapping">
                            <FontAwesomeIcon icon={faSearch} />
                        </span>
                    </div>

                    <div className="action-buttons">

                        {/* <button type="button" className="btn btn-sm btn-outline-secondary ms-2">
                            <FontAwesomeIcon icon={faFilter} className="text-orange"/>
                            <span className="ms-2">Filters</span>
                        </button>
                        <button type="button" className="btn btn-sm btn-outline-secondary ms-2 me-2">
                            <FontAwesomeIcon icon={faEllipsisVertical} className="text-orange"/>
                        </button> */}

                        {showActionButtons &&
                            actionButtons.map((button, index) => {
                                return (<button
                                    data-bs-toggle={button.dataToggle}
                                    data-bs-target={button.dataTarget}
                                    className={`${button.cssClass} ${button.processing ? 'disabled' : ''}`}
                                    key={index}
                                    onClick={button.onClick}>
                                    {button.processing ? 'Please wait...' : button.label}
                                </button>)
                            })
                        }

                    </div>
                </div>

                <XTable
                    selected={selected}
                    onSelectAll={(rows) => handleSelectAll(rows)}
                    onRowClick={(row) => handleClickRow(row)}
                    onCheckRow={(row) => handleCheckRow(row)}
                    data={filteredInputs}
                    columns={columns}
                    loading={loading} />

                <XPagination
                    dataLength={filteredInputs.length}
                    pagination={pagination}
                    currentPage={page}
                    setPage={(page) => setPage(page)} />
            </TableWrapper>

            <CreateNewInputForm pagination={pagination} hubId={hubId} />
            <HubInputDetails close={() => setDrawerVisible(false)} show={drawerVisible} input={selectedInput} />
        </>
    );
}

export default HubInputsPage;